@use "../util" as *;

.cursor {
  position: fixed;
  top: em(-40);
  left: em(-40);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  animation: color 6s infinite;

  &::before {
    content: "";
    width: em(80);
    height: em(80);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: scale(0.15);
    background: var(--light);
    box-shadow: 0 0 em(20) cyan, 0 0 em(60) cyan, 0 0 em(100) cyan;
    border: em(1) solid cyan;
    transition: all 200ms;
    transition-property: animation transform background filter border box-shadow;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  @keyframes color {
    0% {
      filter: hue-rotate(0deg);
    }

    100% {
      filter: hue-rotate(360deg);
    }
  }
}

.cursor.button {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.9);
    filter: opacity(0.35);
    border: none;
    background: linear-gradient(120deg, var(--danger), var(--orange));
  }
}

.cursor.navlinks {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.9);
    filter: opacity(0.35);
    border: none;
    background: linear-gradient(120deg, var(--primary), var(--danger));
  }
}

.cursor.logo {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.9);
    filter: opacity(0.35);
    border: none;
    background: linear-gradient(120deg, var(--light), var(--primary));
  }
}

.cursor.show__hamburger {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.9);
    filter: opacity(0.35);
    border: none;
    background: linear-gradient(360deg, cyan, magenta);
  }
}

.cursor.brand {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.9);
    filter: opacity(0.35);
    border: none;
    background: linear-gradient(45deg, cyan, var(--light));
  }
}

.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 1s;
  transition-delay: 1s;

  .loadbar {
    width: 0%;
    height: 100%;
    max-height: em(6);
    background: var(--primary);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }

  figure {
    width: em(600);
    height: em(600);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade 0.3s;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    position: relative;

    span {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--light);
      text-align: center;
      letter-spacing: em(3);
      font-family: var(--font-galaxus);
      font-size: em(16);
    }

    img {
      height: em(100);
      display: inline-block;
      opacity: 0.05;
    }
  }
}

@keyframes fade {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.page-loaded {
  .preloader {
    height: 0;

    .loadbar {
      max-height: 100%;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    }

    figure {
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.custom__button {
  position: relative;
  display: block;
  width: em(140);
  height: em(55);
  font-family: var(--font-titillium-bold);
  font-size: em(16);
  letter-spacing: em(1);
  color: white;
  background: var(--primary);
  overflow: hidden;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, orange, magenta);
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 400ms;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    z-index: -1;
  }

  &:hover::after {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.link__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: em(140);
  height: em(55);
  font-family: var(--font-titillium-bold);
  font-size: em(16);
  letter-spacing: em(1);
  color: white !important;
  background: var(--primary);
  overflow: hidden;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, orange, magenta);
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 400ms;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    z-index: -1;
  }

  &:hover::after {
    transform-origin: left;
    transform: scaleX(1);
  }
}
