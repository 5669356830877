@use "../util" as *;

#main .branding {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    padding-top: em(120);
  }

  &__column {
    figure img {
      filter: brightness(0.55);
      transition: all 300ms;
      transition-property: filter;
      transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);

      &:hover {
        filter: brightness(0.95);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: em(40);

      @include breakpoints-down(mb2) {
        margin-bottom: em(0);
      }
    }

    &__figure {
      padding: em(10);
    }
  }
}
