@use "../util" as *;

#main .banner {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  z-index: 98;

  &__container {
    width: 100%;
    height: 100%;
  }

  &__row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    width: 100%;
    height: 100%;
    padding-top: em(90);
    padding-left: em(120);

    @include breakpoints-down(mb2) {
      padding-top: em(700);
    }

    & h3:not(:last-of-type) {
      font-family: var(--font-terabite);
      font-size: em(26);
      letter-spacing: em(0.95);
      margin: 0 0 em(-10);
      color: cyan;

      &::after {
        content: "";
        width: 22vw;
        height: em(0.555);
        margin-left: em(6);
        display: inline-block;
        background: cyan;
      }
    }

    h1 {
      font-family: var(--font-terabite);
      font-size: em(122.95);
      text-transform: uppercase;
      color: cyan;

      @for $i from 1 through 4 {
        span:nth-child(#{$i}) {
          color: hsl($i * 25, 50%, 50%);
        }
      }
    }

    & h3:last-of-type {
      font-family: var(--font-terabite);
      font-size: em(30);
      font-weight: 600;
      letter-spacing: em(1.25);
      margin: em(-12.75) em(0) em(26) em(0);
      text-transform: uppercase;
      color: cyan;
    }
  }

  &__canvas {
    width: 100%;
    height: 100vh;
    display: block;
  }
}
