@font-face {
  font-family: "Neonblitz";
  src: url("/font/neonblitz/neonblitz.otf");
  src: url("/font/neonblitz/neonblitz.otf") format("embedded-opentype"),
    url("/font/neonblitz/neonblitz.otf") format("woff"),
    url("/font/neonblitz/neonblitz.otf") format("truetype"),
    url("/font/neonblitz/neonblitz.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Racespace Reglr";
  src: url("/font/racespace/racespace__regular.otf");
  src: url("/font/racespace/racespace__regular.otf") format("embedded-opentype"),
    url("/font/racespace/racespace__regular.otf") format("woff"),
    url("/font/racespace/racespace__regular.otf") format("truetype"),
    url("/font/racespace/racespace__regular.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Racespace Strp";
  src: url("/font/racespace/racespace__stripe.otf");
  src: url("/font/racespace/racespace__stripe.otf") format("embedded-opentype"),
    url("/font/racespace/racespace__stripe.otf") format("woff"),
    url("/font/racespace/racespace__stripe.otf") format("truetype"),
    url("/font/racespace/racespace__stripe.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Terabite";
  src: url("/font/terabite/terabite.ttf");
  src: url("/font/terabite/terabite.ttf") format("embedded-opentype"),
    url("/font/terabite/terabite.ttf") format("woff"),
    url("/font/terabite/terabite.ttf") format("truetype"),
    url("/font/terabite/terabite.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Titillium";
  src: url("/font/titillium/titillium.ttf");
  src: url("/font/titillium/titillium.ttf") format("embedded-opentype"),
    url("/font/titillium/titillium.ttf") format("woff"),
    url("/font/titillium/titillium.ttf") format("truetype"),
    url("/font/titillium/titillium.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Titillium Lit";
  src: url("/font/titillium/titillium__light.ttf");
  src: url("/font/titillium/titillium__light.ttf") format("embedded-opentype"),
    url("/font/titillium/titillium__light.ttf") format("woff"),
    url("/font/titillium/titillium__light.ttf") format("truetype"),
    url("/font/titillium/titillium__light.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Titillium Bld";
  src: url("/font/titillium/titillium__bold.ttf");
  src: url("/font/titillium/titillium__bold.ttf") format("embedded-opentype"),
    url("/font/titillium/titillium__bold.ttf") format("woff"),
    url("/font/titillium/titillium__bold.ttf") format("truetype"),
    url("/font/titillium/titillium__bold.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
