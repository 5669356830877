/* 640px - 1024px - 1400px */
/* $breakpoints-up: (
  "md": "40em",
  "lg": "64em",
  "xl": "87.5em",
); */
/* 639px - 1023px - 1399px */
/* $breakpoints-down: (
  "sm": "39.9375em",
  "md": "63.9375em",
  "lg": "87.4375em",
); */
/* @mixin breakpoint-up($size) {
  @media screen and (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
} */
/* @mixin breakpoint-down($size) {
  @media screen and (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}
 */
/* 375 - 425 - 768 - 1024 - 1366 - 1440 - 1920 */
/* 374 - 424 - 767 - 1023 - 1365 - 1439 - 1919 */
#main {
  background-image: url("https://user-images.githubusercontent.com/26748614/96337246-f14d4580-1085-11eb-8793-a86d929e034d.jpg");
  background-size: cover;
  backdrop-filter: brightness(50%);
}
#main .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.609375em 9.25% !important;
  background: url(/img/header.PNG) !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  transition: all 300ms;
  transition-property: background, background-size, background-position, background-repeat;
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  z-index: 100;
}
#main .header__brand {
  font-family: var(--font-terabite);
}
#main .header__brand a {
  font-size: 2.828125em;
  font-weight: bolder;
  letter-spacing: 0.059375em;
  color: cyan;
}
#main .header__navbar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  clip-path: circle(0% at 50% 50%);
  transition: all 500ms;
  transition-property: clip-path;
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
#main .header__navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("https://user-images.githubusercontent.com/26748614/96337246-f14d4580-1085-11eb-8793-a86d929e034d.jpg");
  background-size: cover;
  backdrop-filter: 50%;
  filter: hue-rotate(45deg) blur(0.140625em) grayscale(0.35);
}
#main .header__navbar ul li {
  display: block;
}
#main .header__navbar ul li:not(:last-child) {
  margin-bottom: 1.875em;
}
#main .header__navbar ul li a {
  position: relative;
  font-family: var(--font-terabite);
  font-size: 1.921875em;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: cyan;
  transition: 200ms;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#main .header__navbar ul li a::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  width: 100%;
  height: 0.0078125em;
  background: cyan;
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#main .header__navbar ul li a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}
#main .header .header__navbar.show {
  clip-path: circle(100% at 50% 50%);
}

.hamburger {
  margin-right: -1.171875em;
}
.hamburger__menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
  z-index: 1000;
}
.hamburger__line {
  fill: none;
  stroke: cyan;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.hamburger__line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.hamburger__line2 {
  stroke-dasharray: 60 60;
  stroke-width: 3;
}
.hamburger__line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.opened .hamburger__line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}
.opened .hamburger__line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 3;
}
.opened .hamburger__line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

#main .banner {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  z-index: 98;
}
#main .banner__container {
  width: 100%;
  height: 100%;
}
#main .banner__row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#main .banner__text {
  width: 100%;
  height: 100%;
  padding-top: 5.625em;
  padding-left: 7.5em;
}
@media screen and (max-width: 26.5em) {
  #main .banner__text {
    padding-top: 43.75em;
  }
}
#main .banner__text h3:not(:last-of-type) {
  font-family: var(--font-terabite);
  font-size: 1.625em;
  letter-spacing: 0.059375em;
  margin: 0 0 -0.625em;
  color: cyan;
}
#main .banner__text h3:not(:last-of-type)::after {
  content: "";
  width: 22vw;
  height: 0.0346875em;
  margin-left: 0.375em;
  display: inline-block;
  background: cyan;
}
#main .banner__text h1 {
  font-family: var(--font-terabite);
  font-size: 7.684375em;
  text-transform: uppercase;
  color: cyan;
}
#main .banner__text h1 span:nth-child(1) {
  color: #bf7540;
}
#main .banner__text h1 span:nth-child(2) {
  color: #bfaa40;
}
#main .banner__text h1 span:nth-child(3) {
  color: #9fbf40;
}
#main .banner__text h1 span:nth-child(4) {
  color: #6abf40;
}
#main .banner__text h3:last-of-type {
  font-family: var(--font-terabite);
  font-size: 1.875em;
  font-weight: 600;
  letter-spacing: 0.078125em;
  margin: -0.796875em 0em 1.625em 0em;
  text-transform: uppercase;
  color: cyan;
}
#main .banner__canvas {
  width: 100%;
  height: 100vh;
  display: block;
}

#main .about {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#main .about__text h1 {
  font-family: var(--font-terabite);
  font-size: 3.4375em;
  line-height: 0.9;
  letter-spacing: 0.125em;
  color: cyan;
  max-width: 4.6875em;
  margin-top: 2.515625em;
}
#main .about__text p {
  font-family: var(--font-titillium);
  color: cyan;
  max-width: 37.5em;
}
#main .about__image {
  max-width: 34.375em;
}

#main .branding {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#main .branding__container {
  padding-top: 7.5em;
}
#main .branding__column figure img {
  filter: brightness(0.55);
  transition: all 300ms;
  transition-property: filter;
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
#main .branding__column figure img:hover {
  filter: brightness(0.95);
}
#main .branding__column:not(:last-of-type) {
  margin-bottom: 2.5em;
}
@media screen and (max-width: 26.5em) {
  #main .branding__column:not(:last-of-type) {
    margin-bottom: 0em;
  }
}
#main .branding__column__figure {
  padding: 0.625em;
}

:root {
  --font-neonblitz: "Neonblitz";
  --font-racespace-reg: "Racespace Reglr";
  --font-racespace-strp: "Racespace Strp";
  --font-terabite: "Terabite";
  --font-tetillium: "Titillium";
  --font-tetillium-lit: "Titillium Lit";
  --font-tetillium-bld: "Titillium Bld";
  --primary: #528ce4;
  --light: #f8f9fa;
  --grey: #6c757d;
  --orange: #ffc107;
  --danger: #dc3545;
  --dark: #212529;
  --primary-background: linear-gradient(225deg, #ffd89b 10%, #19547b 70%);
  --secondary-background: linear-gradient(45deg, #ffd89b 10%, #19547b 70%);
  --focus-background: linear-gradient(45deg, #ffd89b 10%, #dc3545 70%);
  --btn-hover-boxshadow: rgba(0, 0, 0, 0.099) 6px 8px 8px 0px,
    rgba(0, 0, 0, 0.099) 6px 8px 8px 0px, rgba(0, 0, 0, 0.099) -6px 8px 8px 0px,
    rgba(0, 0, 0, 0.099) -6px 8px 8px 0px;
  --btn-focus-boxshadow: rgba(0, 0, 0, 0.099) 0px 8px 8px 0px,
    rgba(0, 0, 0, 0.099) 0px 8px 8px 0px;
  --card-boxshadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-track {
  display: none;
}

*::selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

* {
  border: none;
  outline: none;
  cursor: context-menu;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  overflow-x: hidden;
}

a,
a:active a:visited {
  text-decoration: none;
}

.cursor {
  position: fixed;
  top: -2.5em;
  left: -2.5em;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  animation: color 6s infinite;
}
.cursor::before {
  content: "";
  width: 5em;
  height: 5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: scale(0.15);
  background: var(--light);
  box-shadow: 0 0 1.25em cyan, 0 0 3.75em cyan, 0 0 6.25em cyan;
  border: 0.0625em solid cyan;
  transition: all 200ms;
  transition-property: animation transform background filter border box-shadow;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
@keyframes color {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.cursor.button {
  mix-blend-mode: unset;
}
.cursor.button::before {
  content: "";
  transform: scale(0.9);
  filter: opacity(0.35);
  border: none;
  background: linear-gradient(120deg, var(--danger), var(--orange));
}

.cursor.navlinks {
  mix-blend-mode: unset;
}
.cursor.navlinks::before {
  content: "";
  transform: scale(0.9);
  filter: opacity(0.35);
  border: none;
  background: linear-gradient(120deg, var(--primary), var(--danger));
}

.cursor.logo {
  mix-blend-mode: unset;
}
.cursor.logo::before {
  content: "";
  transform: scale(0.9);
  filter: opacity(0.35);
  border: none;
  background: linear-gradient(120deg, var(--light), var(--primary));
}

.cursor.show__hamburger {
  mix-blend-mode: unset;
}
.cursor.show__hamburger::before {
  content: "";
  transform: scale(0.9);
  filter: opacity(0.35);
  border: none;
  background: linear-gradient(360deg, cyan, magenta);
}

.cursor.brand {
  mix-blend-mode: unset;
}
.cursor.brand::before {
  content: "";
  transform: scale(0.9);
  filter: opacity(0.35);
  border: none;
  background: linear-gradient(45deg, cyan, var(--light));
}

.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 1s;
  transition-delay: 1s;
}
.preloader .loadbar {
  width: 0%;
  height: 100%;
  max-height: 0.375em;
  background: var(--primary);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}
.preloader figure {
  width: 37.5em;
  height: 37.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade 0.3s;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  position: relative;
}
.preloader figure span {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light);
  text-align: center;
  letter-spacing: 0.1875em;
  font-family: var(--font-galaxus);
  font-size: 1em;
}
.preloader figure img {
  height: 6.25em;
  display: inline-block;
  opacity: 0.05;
}

@keyframes fade {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.page-loaded .preloader {
  height: 0;
}
.page-loaded .preloader .loadbar {
  max-height: 100%;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.page-loaded .preloader figure {
  transform: translateY(50px);
  opacity: 0;
}

.custom__button {
  position: relative;
  display: block;
  width: 8.75em;
  height: 3.4375em;
  font-family: var(--font-titillium-bold);
  font-size: 1em;
  letter-spacing: 0.0625em;
  color: white;
  background: var(--primary);
  overflow: hidden;
  z-index: 1;
}
.custom__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, orange, magenta);
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  z-index: -1;
}
.custom__button:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.link__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 8.75em;
  height: 3.4375em;
  font-family: var(--font-titillium-bold);
  font-size: 1em;
  letter-spacing: 0.0625em;
  color: white !important;
  background: var(--primary);
  overflow: hidden;
  z-index: 1;
}
.link__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, orange, magenta);
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  z-index: -1;
}
.link__button:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

@font-face {
  font-family: "Neonblitz";
  src: url("/font/neonblitz/neonblitz.otf");
  src: url("/font/neonblitz/neonblitz.otf") format("embedded-opentype"), url("/font/neonblitz/neonblitz.otf") format("woff"), url("/font/neonblitz/neonblitz.otf") format("truetype"), url("/font/neonblitz/neonblitz.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Racespace Reglr";
  src: url("/font/racespace/racespace__regular.otf");
  src: url("/font/racespace/racespace__regular.otf") format("embedded-opentype"), url("/font/racespace/racespace__regular.otf") format("woff"), url("/font/racespace/racespace__regular.otf") format("truetype"), url("/font/racespace/racespace__regular.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Racespace Strp";
  src: url("/font/racespace/racespace__stripe.otf");
  src: url("/font/racespace/racespace__stripe.otf") format("embedded-opentype"), url("/font/racespace/racespace__stripe.otf") format("woff"), url("/font/racespace/racespace__stripe.otf") format("truetype"), url("/font/racespace/racespace__stripe.otf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Terabite";
  src: url("/font/terabite/terabite.ttf");
  src: url("/font/terabite/terabite.ttf") format("embedded-opentype"), url("/font/terabite/terabite.ttf") format("woff"), url("/font/terabite/terabite.ttf") format("truetype"), url("/font/terabite/terabite.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Titillium";
  src: url("/font/titillium/titillium.ttf");
  src: url("/font/titillium/titillium.ttf") format("embedded-opentype"), url("/font/titillium/titillium.ttf") format("woff"), url("/font/titillium/titillium.ttf") format("truetype"), url("/font/titillium/titillium.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Titillium Lit";
  src: url("/font/titillium/titillium__light.ttf");
  src: url("/font/titillium/titillium__light.ttf") format("embedded-opentype"), url("/font/titillium/titillium__light.ttf") format("woff"), url("/font/titillium/titillium__light.ttf") format("truetype"), url("/font/titillium/titillium__light.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Titillium Bld";
  src: url("/font/titillium/titillium__bold.ttf");
  src: url("/font/titillium/titillium__bold.ttf") format("embedded-opentype"), url("/font/titillium/titillium__bold.ttf") format("woff"), url("/font/titillium/titillium__bold.ttf") format("truetype"), url("/font/titillium/titillium__bold.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}